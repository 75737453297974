@import './colors.css';
@import './illustration-background.css';
/* GLOBAL */
html {
    margin: 0;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: normal;
    line-height: 1rem;
    margin-bottom: 0;
    margin-top: 0!important;
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
    font-size: 24px;
}

body, html, label, h1, h2, h3, h4, h5, h6, div, button, input {
    color: var(--dark-gray);
    font-family: "Metropolis","Avenir Next","Helvetica Neue",Arial,sans-serif;
}

body {
    margin: 0;
    padding: 0;
    height: 100vh;
    font-family: Open Sans, sans-serif;
    font-size: 13px;
    /* color: #111c3e; */
    background-color: #fff;
    overflow: hidden;
}

#root {
    height: 100%;
    display: flex;
    /* background-image: var(--background-illustration); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1000px, contain;
}

input {
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: -internal-light-dark(black, white);
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: textfield;
    background-color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59));
    -webkit-rtl-ordering: logical;
    cursor: text;
    margin: 0em;
    /* font: 400 13.3333px Arial; */
    padding: 2px 0px;
    border-width: 2px;
    border-style: inset;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;

    color: var(--very-dark-blue);
    width: 100%;
    border: none;
    background: 0 0;
    line-height: 30px;
    border-bottom: 1px solid var(--light-blue);
    margin-bottom: 15px;
    outline: 0;
    font-size: 120%;
    font-weight: 600;
}

input::placeholder {
    color: rgba(128, 128, 128, 0.598);
    font-size: 15px;
    font-weight: 400;
  }

label {
    color: var(--very-dark-blue);
    font-weight: 500;
    display: block;
    font-size: 13px;
    text-align: left;
    margin-bottom: 2px;
    /* padding-left: 1rem; */
}


.btn {
    border: none;
    line-height: 35px;
    text-align: center;
    width: auto;
    background: transparent;
    border-radius: 4px;
    border: 1px solid;
    padding: 0 15px;
    transition: all .2s ease-out;
    cursor: pointer;
    font-weight: 500;
}

.btn:disabled {
    opacity: 0.4;
    cursor: not-allowed;
}


.btn-primary {
    text-transform: uppercase;
    background-color: var(--purple);
    color: white;
}

.btn-link {
    padding: 0;
    border: none;
    color: var(--dark-blue);
    padding: 0 8px;
    margin-left: -8px;
}

.btn-link:hover{
    background: #f6fafe;
}

.btn-link:focus {
    outline: none;
}

form.form {
    padding: 10px 15px;
}

.align-center {
    text-align: center;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}


div.form-footer {
    margin-top: 5px;
    display:  flex;
}

div.left-side {
    text-align: left;
    width: 100%;
}

div.right-side {
    text-align: right;
    width: 100%;
}

.form-alerts {
    text-align: end;
    color: var(--red);
    font-size: 12px;
}



@media only screen and (max-width: 500px) {
    .btn {
        width: 100%;
        margin-bottom: 3px;
    }

    .btn-link {
        padding: 0;
        margin-left: 0;
    }

    div.form-footer {
        display:  block;
    }
  }