div.legal-infos {
    position: absolute;
    bottom: 10px;
    left: 20px;
    font-size: 12px;
}

div.legal-infos p a {
    text-decoration: none;
    color: initial;
}

div.legal-infos p {
    text-decoration: none;
    color: initial;
}

div.legal-infos p a:hover {
    text-decoration: underline;
}