@import '../../style/colors.css';

.bar-animation {
    width: 50%;
    height: 3px;
    background: var(--dark-blue);
    position: absolute;
    transition: 1s all ease-out;
    animation-name: loading-interminate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

@keyframes loading-interminate {
  0% {
    left: 0;
    width: 0;
  }
  
  50% {
    left: 10%;
  }
  
  70% {
    left: 60%;
  }
  
  100% {
    left: 100%;
    right: 0;
  }
}