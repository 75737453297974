@import '../../style/colors.css';

div.label {
    border: 1px solid var(--light-gray);
    border-radius: 30px;
    padding: 3px 14px;
    cursor: default;
    font-weight: 500;
    display: inline-block;
}

.avatar {
    color: white;
    background: var(--dark-gray);
    border-radius: 50%;
    padding: 3px;
    margin-right: 3px;
}