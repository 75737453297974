:root {
    --transparent-white: #fffffff9;

    --very-light-gray: #dadce0;
    --light-gray: #cccccc;
    --gray: #bdbdbd;
    --dark-gray: #565656;

    --light-blue: #3a54a9;
    --blue: #119ef1;
    --dark-blue: #003e8c;
    --very-dark-blue: #1b203e;

    --purple: #543ccc;
    
    --light-orange: #ffa73a;
    --orange: #e64f22;

    --red: #da3200;

    --green : #56bd7a;
  }