@import '../../style/colors.css';
@import '../../style/logitud-logo.css';

div.homePage {
    width: 100%;
    margin: auto;
}


.formBox {
    flex-wrap: wrap;
    margin-top: -100px;
    margin: auto;
    padding: 40px 0;
    /* border-radius: 8px; */
    display: block;
    flex-shrink: 0;
    min-height: 0;
    transition: .2s;
    width: 450px;
    text-align: center;
    position: relative;
    overflow: hidden;
    background-color: white;
    /* box-shadow: 0 0 2px 0 #ececec; */
    box-shadow: 0px 1px 3px 0 var(--gray);
    /* box-shadow: 0 0 3px 0 var(--gray); */
    /* border-top: 3px solid var(--dark-blue); */
}

.formBox.noTopBorder {
    border-top: none;
}

.freeze-view {
    pointer-events: none;
    opacity: 0.7;
}


div.loading-div {
    position: absolute;
    top: 0;
    width: 100%;
}

.header {
    margin-bottom: 1rem;
    padding: 0 1rem;
}

.logo-container {
    margin-bottom: 1rem;
}

.logitud_logo {
    background-image: var(--logitud-logo);
    height: 70px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.title {
    font-size: 20px;
    font-weight: 400;
    color: var(--very-dark-blue);
}

.content {
    padding: 0 1rem;
}

div.alert-message {
    padding: 10px 1rem;
    padding-top: 0;
}


@media only screen and (max-width: 500px) {
    .formBox {
        height: 100%;
        width: 100%;
        border: none;
        border-radius: 0;
    }
    div.homePage {
        margin: unset;
    }
  }