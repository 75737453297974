@import '../../style/colors.css';

.alert {
    color: white;
    padding: 8px 10px;
    border-radius: 4px;
    box-shadow: 0 0 1px 0px var(--light-orange);
    margin: 0 15px;
}

.warning-message {
    background: var(--light-orange);

}

.error-message {
    background: var(--orange);
}

.info-message {
    background: var(--blue);
}

.success-message {
    background: var(--green);
}


.icon-side {
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.text-side {
    text-align: left;
    color: white;
    padding: 0 10px;
    margin: auto 0;
}

.flex {
    display: flex;
}

.text {
    color: white;
}